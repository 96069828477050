<template>
	<div class="news">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-lg-3 mb-5">
					<SideBar
						id="tmtNewsSideBar"
						:btns="btns"
						:value="contentId"
					></SideBar>
				</div>
				<div class="col-12 col-lg-9">
					<div
						class="container-fluid"
						v-if="contentId === '2021-smart-city-ai-50'"
					>
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										得美特獲得2021智慧城市展AI50優勝獎
									</span>
								</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									<span
										>得美特科技以「微氣候能量AI神經系統」參加2021年智慧城市展AI
										50競賽，榮獲優勝獎殊榮，並獲邀於3/23至3/26日在南港展覽2館，</span
									>
									<span class="text-bold text-highlight">攤位編號：Q1306</span>
									<span
										>，AI50專區展出得獎作品，並於3/24日以「中央空調專家AI控制神經系統」專題發表演講</span
									>
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/news_03_01.png" />
							</div>
						</div>
					</div>

					<div class="container-fluid" v-if="contentId === '2019-ai-star'">
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										得美特獲得AI Star 國際選秀賽特優獎
									</span>
								</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									得美特科技以「中央空調AI神經系統」參加經濟部舉辦2019年AI
									Star國際選秀全國前三名之特優獎殊榮。並獲選為AWS亞馬遜雲端服務為台灣創新技術夥伴與ISV軟體開發夥伴！
								</p>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<img class="w-100" src="../assets/news_02_01.png" />
							</div>
						</div>
					</div>

					<div class="container-fluid" v-if="contentId === '2020-udn'">
						<div class="row">
							<div class="col">
								<h1>
									<span class="primary text-bold">
										聯合報採用「微氣候能量AI執行系統」創下超高節能率
									</span>
								</h1>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>
									聯合報集團於2018年汱換磁浮離心式冰水主機，運行一年多後先發揮高效率主機節能效果，於2020年導入「微氣候能量AI執行系統」後，再將冰水主機潛力發揮節能25.7%，並穩定室內微氣候±0.5˚C
									波動範圍
								</p>
								<p>
									<router-link class="link-text" to="/case/aies-hvac">
										完整成功案例介紹，請參考此處
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SideBar from '@/components/SideBar.vue';

export default {
	name: 'News',
	components: {
		SideBar,
	},
	data() {
		return {
			contentId: '',
			btns: [
				{
					id: '2021-smart-city-ai-50',
					text: '得美特獲得2021智慧城市展AI50優勝獎',
					link: '/news/2021-smart-city-ai-50',
				},
				{
					id: '2019-ai-star',
					text: '得美特獲得AI Star 國際選秀賽特優獎',
					link: '/news/2019-ai-star',
				},
				{
					id: '2020-udn',
					text: '聯合報採用「微氣候能量AI執行系統」創下超高節能率',
					link: '/news/2020-udn',
				},
			],
		};
	},
	computed: {
		contentIds() {
			return this.btns.map((btn) => btn.id);
		},
	},
	methods: {
		ContentIdReload(target) {
			let result = this.contentIds[0];
			if (this.contentIds.includes(target)) {
				result = target;
			}
			this.contentId = result;
		},
	},
	watch: {
		$route() {
			this.ContentIdReload(this.$route.params.name_id);
		},
	},
	created() {
		this.ContentIdReload(this.$route.params.name_id);
	},
};
</script>

<style scoped>
h1 .primary {
	color: rgb(45, 177, 96);
	font-size: 1.75rem;
}
h1 .secondary {
	color: rgb(234, 90, 49);
	margin-left: 1rem;
	font-size: 1.75rem;
}
h2 {
	color: rgb(192, 0, 0);
	font-size: 1.5rem;
}
h3 {
	color: rgb(234, 90, 49);
	font-size: 1.25rem;
}
.news {
	font-size: 1.25rem;
}
.text-bold {
	font-weight: bold;
}
.text-highlight {
	color: rgb(234, 90, 49);
}
.link-text {
	color: #03c967;
}
</style>
